import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import StarIcon from "@material-ui/icons/Star";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/pillsStyle.js";

const useStyles = makeStyles(styles);

export default function SectionPills() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div id="navigation-pills">
          <div className={classes.title}>
            <h3>Avis client</h3>
          </div>
          {/* <div className={classes.title}>
            <h3>
              <small>With Icons</small>
            </h3>
          </div> */}
          <GridContainer>
            <GridItem>
              <NavPills
                color="rose"
                tabs={[
                  {
                    tabButton: "Stéphanie",
                    tabIcon: StarIcon,
                    tabContent: (
                      <span>
                        <p>
                          Agence très Pro. Le travail est réalisé avec sérieux
                          et dans des délais raisonnables. Le véhicule est
                          restitué nettoyé (aspirateur + vitres). Courtois et
                          commerçant. Merci
                        </p>
                        <br />
                        <p>Stéphanie</p>
                        <p>Les Pavillons-sous-Bois</p>
                        <br />
                      </span>
                    ),
                  },
                  {
                    tabButton: "Adrien",
                    tabIcon: StarIcon,
                    tabContent: (
                      <span>
                        <p>
                          Bon professionnel. Accueil et explications
                          impeccables. Rien à faire au niveau de la démarche
                          auprès de l'assurance. Véhicule nettoyé offert
                        </p>
                        <br />
                        <p>Adrien</p>
                        <p>Sainte-Geneviève-des-Bois</p>
                        <br />
                      </span>
                    ),
                  },
                  {
                    tabButton: "Sofiane",
                    tabIcon: StarIcon,
                    tabContent: (
                      <span>
                        <p>
                          Après avoir contacté Kf pare-brise ils sont intervenu
                          48h après mon appel très réactif et du bon travail !
                        </p>
                        <br />
                        <p>Sofiane</p>
                        <p>Bagneux</p>
                        <br />
                      </span>
                    ),
                  },
                ]}
              />
            </GridItem>
            {/* <GridItem xs={12} sm={12} md={12} lg={6}>
              <NavPills
                color="rose"
                horizontal={{
                  tabsGrid: { xs: 12, sm: 4, md: 4 },
                  contentGrid: { xs: 12, sm: 8, md: 8 }
                }}
                tabs={[
                  {
                    tabButton: "Dashboard",
                    tabIcon: Dashboard,
                    tabContent: (
                      <span>
                        <p>
                          Collaboratively administrate empowered markets via
                          plug-and-play networks. Dynamically procrastinate B2C
                          users after installed base benefits.
                        </p>
                        <br />
                        <p>
                          Dramatically visualize customer directed convergence
                          without revolutionary ROI. Collaboratively
                          administrate empowered markets via plug-and-play
                          networks. Dynamically procrastinate B2C users after
                          installed base benefits.
                        </p>
                        <br />
                        <p>
                          Dramatically visualize customer directed convergence
                          without revolutionary ROI. Collaboratively
                          administrate empowered markets via plug-and-play
                          networks. Dynamically procrastinate B2C users after
                          installed base benefits.
                        </p>
                      </span>
                    )
                  },
                  {
                    tabButton: "Schedule",
                    tabIcon: Schedule,
                    tabContent: (
                      <span>
                        <p>
                          Efficiently unleash cross-media information without
                          cross-media value. Quickly maximize timely
                          deliverables for real-time schemas.
                        </p>
                        <br />
                        <p>
                          Dramatically maintain clicks-and-mortar solutions
                          without functional solutions. Dramatically visualize
                          customer directed convergence without revolutionary
                          ROI. Collaboratively administrate empowered markets
                          via plug-and-play networks. Dynamically procrastinate
                          B2C users after installed base benefits.
                        </p>
                      </span>
                    )
                  }
                ]}
              />
            </GridItem> */}
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
