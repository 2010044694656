import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import classNames from "classnames";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import styles from "assets/jss/material-kit-react/views/componentsSections/completedStyle.js";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import SpeedIcon from "@material-ui/icons/Speed";
import EuroIcon from "@material-ui/icons/Euro";
import ReceiptIcon from "@material-ui/icons/Receipt";
import PhoneIcon from "@material-ui/icons/Phone";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import Card from "components/Card/Card.js";
import team1 from "assets/img/faces/fb1.png";
import team2 from "assets/img/faces/sss.png";
import team3 from "assets/img/faces/instaa.png";

const useStyles = makeStyles(styles);

export default function SectionCompletedExamples() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2>Intervention à domicile ou en entreprise</h2>
            <h4>
              K &amp; F Pare-Brise vous propose un service professionnel pour la
              réparation de votre pare-brise. Nous intervenons dans les 48
              heures après votre appel.
            </h4>
            <h4>
              Que vous soyez une entreprise ou un particulier, ne laissez plus
              votre véhicule immobilisé. Votre sécurité et votre visibilité sont
              essentiels pour nous.
            </h4>

            <InfoArea
              title="Voitures, utilitaires & poids lourds"
              description="Nous intervenons sur tous types de véhicule toutes marques."
              icon={DriveEtaIcon}
              iconColor="rose"
            />
            <InfoArea
              title="Toutes assurances aucun frais a avancer"
              description="Réparation prise en charge à 100% par tous les assureurs."
              icon={ReceiptIcon}
              iconColor="rose"
            />
            <InfoArea
              title="Rapidité"
              description="Nous intervenons le plus rapidement possible après avoir pris connaissance de votre demande 48h maximum."
              icon={SpeedIcon}
              iconColor="rose"
            />
            <InfoArea
              title="Franchises offerte"
              description="Franchise offerte jusqu'à 150€."
              icon={EuroIcon}
              iconColor="rose"
            />
            <a href="tel:09.86.06.07.39">
              <InfoArea
                title="09.86.06.07.39"
                icon={PhoneIcon}
                iconColor="info"
              />
            </a>
            <a href="mailto:service.gestion@kfparebrise.com">
              <InfoArea
                title="service.gestion@kfparebrise.com"
                icon={AlternateEmailIcon}
                iconColor="info"
              />
            </a>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <a href="https://www.facebook.com/kf.parebrise">
                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                  <img src={team1} alt="..." className={imageClasses} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Facebook
                  <br />
                  <small className={classes.smallTitle}>Kf Pare-brise</small>
                </h4>
              </a>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <a href="https://www.snapchat.com/add/kfparebrise">
                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                  <img src={team2} alt="..." className={imageClasses} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Snapchat
                  <br />
                  <small className={classes.smallTitle}>kfparebrise</small>
                </h4>
              </a>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <a href="https://instagram.com/kfparebrise?igshid=rlk4lca92edu">
                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                  <img src={team3} alt="..." className={imageClasses} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Instagram
                  <br />
                  <small className={classes.smallTitle}>kfparebrise</small>
                </h4>
              </a>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
